import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import db from '../firebase/firebaseInit';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    products: [],
    bag: [],
    allOrders: [],
    userOrders: [],

    newOrders: [],
    inProgressOrders: [],
    deliveredOrders: [],

    bestSellers: [],
    newCollection: [],
    tops: [],
    dresses: [],
    bottoms: [],
    dressSets: [],

    collectionName: null,
    salePercent: null,
    saleBDT: null,
    saleIf: null,
    saleText: null,
    testimonials: [],

    productLoaded: null,
    productTitle: null,
    productDescription: null,
    productPrice: null,
    productQuantity: null,
    productColors: null,
    productImages: null,
    productTags: null,
    productSizeChart: null,

    user: null,
    profileAdmin: null,
    profileEmail: null,
    profileFirstName: null,
    profileLastName: null,
    profileAddress: null,
    profilePhone: null,
    profilePhone2: null,
    profileId: null,
  },
  getters: {
  },
  mutations: {
    removeItemFromBag(state, payload) {
      state.bag = state.bag.filter(
        (post) => post.productId !== payload.productId || post.selectedColor !== payload.selectedColor || post.measurements !== payload.measurements,
      );
    },
    emptyBag(state) {
      state.bag = [];
    },
    removeAdmin(state) {
      state.profileAdmin = null;
    },
    newProduct(state, payload) {
      state.product = payload;
    },
    updateProductTitle(state, payload) {
      state.productTitle = payload;
    },
    updateProductDescription(state, payload) {
      state.productDescription = payload;
    },
    updateProductPrice(state, payload) {
      state.productPrice = payload;
    },
    updateProductQuantity(state, payload) {
      state.productQuantity = payload;
    },
    updateProductColors(state, payload) {
      state.productColors = payload;
    },
    updateProductTags(state, payload) {
      state.productTags = payload;
    },
    updateProductImages(state, payload) {
      state.productImages = payload;
    },
    updateProductSizeChart(state, payload) {
      state.productSizeChart = payload;
    },
    setProductState(state, payload) {
      state.productTitle = payload.productTitle;
      state.productDescription = payload.productDescription;
      state.productPrice = payload.productPrice;
      state.productQuantity = payload.productQuantity;
      state.productColors = payload.productColors;
      state.productImages = payload.productImages;
      state.productSizeChart = payload.productSizeChart;
      state.productTags = payload.productTags;
    },
    updateUser(state, payload) {
      state.user = payload;
    },
    addToBag(state, payload) {
      let itemFound = false;
      state.bag.forEach((product) => {
        if (product.productId === payload.productId && product.selectedColor === payload.selectedColor && product.measurements === payload.measurements) {
          product.quantity += payload.quantity;
          itemFound = true;
        }
      });
      if (!itemFound) {
        state.bag.push(payload);
      }
    },
    increaseItemQuantity(state, payload) {
      const item = state.bag.find(
        (product) =>
          product.productId === payload.productId &&
          product.selectedColor === payload.selectedColor &&
          product.measurements === payload.measurements
      );
      if (item) {
        item.quantity++;
      }
    },
    decreaseItemQuantity(state, payload) {
      const item = state.bag.find(
        (product) =>
          product.productId === payload.productId &&
          product.selectedColor === payload.selectedColor &&
          product.measurements === payload.measurements
      );
      if (item && item.quantity > 1) {
        item.quantity--;
      }
    },
    setProfileInfo(state, doc) {
      state.profileId = doc.id;
      state.profileEmail = doc.data().email;
      state.profileFirstName = doc.data().firstName;
      state.profileLastName = doc.data().lastName;
      state.profileAddress = doc.data().address;
      state.profilePhone = doc.data().phone;
      state.profilePhone2 = doc.data().phone;
      state.profileAdmin = doc.data().admin;
    },
    setSystemVariables(state, doc) {
      state.collectionName = doc.data().collectionName;
      state.salePercent = doc.data().salePercent;
      state.saleIf = doc.data().saleIf;
      state.saleBDT = doc.data().saleBDT;
      state.saleText = doc.data().saleText;
    },
    setTestimonials(state, doc) {
      state.testimonials = doc.data().testimonials;
    },
    changeCollectionName(state, payload) {
      state.collectionName = payload;
    },
    changeHomeCollectionOne(state, payload) {
      state.homeCollectionOne = payload;
    },
    changeHomeCollectionTwo(state, payload) {
      state.homeCollectionTwo = payload;
    },
    changeSalePercent(state, payload) {
      state.salePercent = payload;
    },
    changeSaleBDT(state, payload) {
      state.saleBDT = payload;
    },
    changeSaleIf(state, payload) {
      state.saleIf = payload;
    },
    changeSaleText(state, payload) {
      state.saleText = payload;
    },
    changeFirstName(state, payload) {
      state.profileFirstName = payload;
    },
    changeLastName(state, payload) {
      state.profileLastName = payload;
    },
    changeAddress(state, payload) {
      state.profileAddress = payload;
    },
    changePhone(state, payload) {
      state.profilePhone = payload;
    },
    changePhone2(state, payload) {
      state.profilePhone2 = payload;
    },
    changeEmail(state, payload) {
      state.profileEmail = payload;
    },
  },
  actions: {
    async getCurrentUser({ commit }) {
      const dataBase = await db
        .collection('users')
        .doc(firebase.auth().currentUser.uid);
      const dbResults = await dataBase.get();
      commit('setProfileInfo', dbResults);
    },
    async getSystemVariabels({ commit }) {
      const dataBase = await db
        .collection('systemVariables').doc('MsVHgT9B5tHtnGTo9jlH');
      const dbResults = await dataBase.get();
      commit('setSystemVariables', dbResults);
    },
    async getTestimonials({ commit }) {
      const dataBase = await db.collection('systemVariables').doc('Yrn11zrLHn7bqGVt7pjg');
      const dbResults = await dataBase.get();
      commit('setTestimonials', dbResults);
    },
    async linkOrderToProfile({ state }, payload) {
      const dataBase = await db.collection('orders').doc(payload);
      await dataBase.update({
        profileId: state.profileId,
      });
      this.dispatch('getUserOrders', state.profileId);
    },
    async getOrders({ state }) {
      state.newOrders = [];
      state.inProgressOrders = [];
      state.deliveredOrders = [];
      state.allOrders = [];
      const dataBase = await db.collection('orders').orderBy('orderDate', 'desc');
      const dbResults = await dataBase.get();
      dbResults.forEach((doc) => {
        const data = {
          orderId: doc.data().orderId,
          orderDate: new Date(doc.data().orderDate).toLocaleDateString(),
          profileId: doc.data().profileId,
          firstName: doc.data().firstName,
          address: doc.data().address,
          phone: doc.data().phone,
          orderedProducts: doc.data().orderedProducts,
          orderTotal: doc.data().orderTotal,
          status: doc.data().status,
          paymentMethod: doc.data().paymentMethod,
          bkashNumber: doc.data().bkashNumber,
        };
        state.allOrders.push(data);
        if (doc.data().status === 'Received') {
          state.newOrders.push(data);
        }
        if (doc.data().status === 'In Progress') {
          state.inProgressOrders.push(data);
        }
        if (doc.data().status === 'Delivered') {
          state.deliveredOrders.push(data);
        }
      });
    },
    async getUserOrders({ state }, payload) {
      state.userOrders = [];
      const dataBase = await db.collection('orders').where('profileId', '==', payload);
      console.log(payload);
      const dbResults = await dataBase.get();
      dbResults.forEach((doc) => {
        const data = {
          orderId: doc.data().orderId,
          orderDate: new Date(doc.data().orderDate).toLocaleDateString(),
          profileId: doc.data().profileId,
          firstName: doc.data().firstName,
          address: doc.data().address,
          phone: doc.data().phone,
          orderedProducts: doc.data().orderedProducts,
          orderTotal: doc.data().orderTotal,
          status: doc.data().status,
          paymentMethod: doc.data().paymentMethod,
          bkashNumber: doc.data().bkashNumber,
        };
        state.userOrders.push(data);
      });
    },
    async getProducts({ state }) {
      const dataBase = await db.collection('products').orderBy('date', 'desc');
      const dbResults = await dataBase.get();
      dbResults.forEach((doc) => {
        const data = {
          productId: doc.data().productId,
          productColors: doc.data().colors,
          productDescription: doc.data().description,
          productTitle: doc.data().title.trim(),
          productPrice: doc.data().price,
          productTags: doc.data().tags,
          productDate: doc.data().date,
          productQuantity: doc.data().quantity,
          productImages: doc.data().images,
          productSizeChart: doc.data().sizeChart,
        };
        state.products.push(data);
        if (doc.data().tags.includes('Best Seller')) {
          state.bestSellers.push(data);
        }
        if (doc.data().tags.includes('New Collection')) {
          state.newCollection.push(data);
        }
        if (doc.data().tags.includes('Tops')) {
          state.tops.push(data);
        }
        if (doc.data().tags.includes('Dresses')) {
          state.dresses.push(data);
        }
        if (doc.data().tags.includes('Bottoms')) {
          state.bottoms.push(data);
        }
        if (doc.data().tags.includes('Dress Sets')) {
          state.dressSets.push(data);
        }
      });
      state.productLoaded = true;
    },
    async deleteProduct({ commit }, payload) {
      const getProduct = await db.collection('products').doc(payload);
      await getProduct.delete();
      commit('productDeleted');
      this.dispatch('getProducts');
    },
    async updateOrderStatusToInProgress({ commit }, payload) {
      const dataBase = await db.collection('orders').doc(payload);
      await dataBase.update({
        status: 'In Progress',
      });
      commit('setOrderStatusToInProgress');
      this.dispatch('getOrders');
    },
    async updateOrderStatusToDelivered({ commit }, payload) {
      const dataBase = await db.collection('orders').doc(payload);
      await dataBase.update({
        status: 'Delivered',
      });
      commit('setOrderStatusToDelivered');
      this.dispatch('getOrders');
    },
    async updateProductQuantityInDB({ commit, state }, payload) {
      const dataBase = await db.collection('products').doc(payload.productId);
      const prod = state.products.find(product => product.productId === payload.productId);
      const left = prod.productQuantity - payload.quantity;
      await dataBase.update({
        quantity: left,
      });
    },
    async updateUserSettings({ commit, state }) {
      const dataBase = await db.collection('users').doc(state.profileId);
      await dataBase.update({
        firstName: state.profileFirstName,
        lastName: state.profileLastName,
        address: state.profileAddress,
        phone: state.profilePhone,
        phone2: state.profilePhone2,
      });
    },
    async updateCollection({ commit, state }) {
      const dataBase = await db.collection('systemVariables').doc('MsVHgT9B5tHtnGTo9jlH');
      await dataBase.update({
        collectionName: state.collectionName,
        salePercent: state.salePercent,
        saleIf: state.saleIf,
        saleBDT: state.saleBDT,
        saleText: state.saleText,
      });
      commit('setCollection');
    },
  },
  modules: {},
  plugins: [
    createPersistedState({
      paths: ['bag'],
    }),
  ],
});
