<template>
  <div style="font-size: 12px;">
    <div v-if="!admin && user" style="padding-bottom: 24px">
      <v-text-field v-model="linkOrderId" placeholder="Order ID"
        label="Not seeing an order? Enter Order Id to link order to your profile"></v-text-field>
      <v-btn rounded @click="linkOrder" color="primary">Link order</v-btn>
    </div>
    <vs-row>
      <vs-col v-for="item in orders" v-bind:key="item.orderId" style="padding: 0.5%"
        :w="$vuetify.breakpoint.smAndDown ? 12 : 6">
        <v-card tile :style="`padding: 2%; background-color: ${COLORS.PRIMARY}`">
          <vs-row style="height: 100%">
            <vs-col v-if="user || requestedOrder" w="12" style="margin-bottom: 12px;">
              <Timeline :item="item" />
              <div v-if="$vuetify.breakpoint.smAndDown" style="padding: 4px 0; font-weight: bold;">Status: {{ item.status }}</div>
            </vs-col>
            <vs-col w="4">
              <div>
                <div><strong>Order Id: </strong> {{ item.orderId }}</div>
                <div><strong>Order Date: </strong> {{ item.orderDate }}</div>
                <div><strong>Order Total: </strong> <i class="fa-solid fa-bangladeshi-taka-sign"></i> {{ item.orderTotal
                  }}</div>
                <div v-if="!user"><strong>Name: </strong> {{ item.firstName }}</div>
                <div v-if="!user"><strong>Phone: </strong> {{ item.phone }}</div>
                <div v-if="!user && item.phone2"><strong>Alternate phone: </strong> {{ item.phone2 }}</div>
                <div><strong>Shipping Address: </strong> {{ item.address }}</div>
                <div v-if="item.paymentMethod"><strong>Payment Method: </strong> {{ item.paymentMethod }}</div>
                <div v-if="item.bkashNumber"><strong>Bkash Number/Id: </strong> {{ item.bkashNumber }}</div>
              </div>
            </vs-col>
            <vs-col w="8">
              <v-slide-group show-arrows>
                <v-slide-item v-for="prod in item.orderedProducts" v-bind:key="prod.productId"
                  :style="{ padding: '0 12px' }">
                  <router-link style="text-decoration: none"
                    :to="{ name: 'ViewProduct', params: { productId: prod.productId } }">
                    <v-card width="100" height="100%" tile flat
                      :style="{ padding: '0 1px', backgroundColor: COLORS.PRIMARY }">
                      <v-img width="100%" height="100" v-bind:src="prod.productImage">
                        <template v-slot:placeholder>
                          <v-skeleton-loader class="mx-auto" width="100%" height="100%" type="image"></v-skeleton-loader>
                        </template>
                      </v-img>
                      <div><strong>Title: </strong> {{ prod.productTitle }}</div>
                      <div><strong>Price: </strong><i class="fa-solid fa-bangladeshi-taka-sign"></i> {{
                        prod.productPrice }}</div>
                      <div v-if="prod.color"><strong>Color: </strong> {{ prod.color }}</div>
                      <div><strong>Quantity: </strong>{{ prod.quantity }}</div>
                      <div><strong>Size: </strong>{{ prod.measurements }}</div>
                    </v-card>
                  </router-link>
                </v-slide-item>
              </v-slide-group>
            </vs-col>
            <vs-row style="padding-top: 10px;" justify="center">
                <v-btn v-if="admin" rounded dark small @click.prevent="updateCollection(item.orderId)" v-text="status"> </v-btn>
              </vs-row>
          </vs-row>
        </v-card>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import { COLORS } from '@/plugins/branding.js';
import { toggleLoading } from '@/components/js/loading.js';

export default {
  name: 'Orders',
  props: ['orders', 'user', 'status', 'commit', 'admin', 'requestedOrder'],
  data() {
    return {
      COLORS,
      linkOrderId: null,
    };
  },
  components: {
    Timeline: () => import('@/components/Timeline.vue'),
  },
  methods: {
    toggleLoading(isLoading) {
      toggleLoading(this, isLoading);
    },
    updateCollection(orderId) {
      this.toggleLoading(true);
      try {
        this.$store.dispatch(this.commit, orderId);
      } catch (err) {
        // console.log(err);
      }
      this.toggleLoading(false);
    },
    linkOrder() {
      this.toggleLoading(true);
      try {
        this.$store.dispatch('linkOrderToProfile', this.linkOrderId);
      } catch (err) {
        // console.log(err);
      }
      this.linkOrderId = null;
      this.toggleLoading(false);
    },
  },
};
</script>